import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Play } from "./assets/play.svg";
import { ReactComponent as Pause } from "./assets/pause.svg";
import { ReactComponent as Forwards } from "./assets/forward.svg";
import { ReactComponent as Backwards } from "./assets/backward.svg";

interface AudioPlayerProps {
    audioSrc: string;
    title: string;
    artist: string;
}

interface AudioControlsProps {
    isPlaying: boolean;
    onPlayPauseClick: CallableFunction;
    // onPrevClick: MouseEventHandler;
    // onNextClick: MouseEventHandler;
}

//https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
// https://www.iconbolt.com/iconsets/iconsax-outline/forward-10-seconds

const AudioControls = ({ isPlaying, onPlayPauseClick }: AudioControlsProps) => {
    return (
        <AudioControlsContainer>
            <PlayPause className="back" onClick={() => onPlayPauseClick(false)} aria-label="Pause">
                <Backwards />
            </PlayPause>
            {isPlaying ? (
                <PlayPause className="pause" onClick={() => onPlayPauseClick(false)} aria-label="Pause">
                    <Pause />
                </PlayPause>
            ) : (
                <PlayPause className="play" onClick={() => onPlayPauseClick(true)} aria-label="Play">
                    <Play />
                </PlayPause>
            )}
        </AudioControlsContainer>
    );
};

export const AudioPlayer = ({ audioSrc, title }: AudioPlayerProps) => {
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = useRef(
        new Audio(
            "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV"
        )
    );
    const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);
    const isReady = useRef(false);

    const { duration } = audioRef.current;

    useEffect(() => {
        if (isPlaying) {
            if (!isReady.current) {
                audioRef.current.src = audioSrc;
                isReady.current = true;
            }
            audioRef.current.play();
            startTimer();
        } else {
            if (intervalRef.current) clearInterval(intervalRef.current);
            audioRef.current.pause();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying]);

    useEffect(() => {
        // Pause and clean up on unmount
        const localRef = audioRef;
        audioRef.current.autoplay = true;
        return () => {
            localRef.current.pause();
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startTimer = () => {
        console.log("Sart time");
        // Clear any timers already running
        if (intervalRef.current) clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            if (audioRef.current.ended) {
                setIsPlaying(false);
            } else {
                setTrackProgress(audioRef.current.currentTime);
            }
        }, 30);
    };

    const onScrub = (value: string) => {
        // Clear any timers already running
        if (intervalRef.current) clearInterval(intervalRef.current);
        audioRef.current.currentTime = parseFloat(value);
        setTrackProgress(audioRef.current.currentTime);
    };

    const onScrubEnd = () => {
        console.log("scrub end");
        // If not already playing, start
        if (!isPlaying) {
            setIsPlaying(true);
        }
        startTimer();
    };

    const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : "0%";
    const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #f6e922)) !important
`;

    return (
        <>
            <AudioPlayerComponent>
                <div className="track-info">
                    {/*<img className="artwork" src={"TSC.jpg"} alt={`track artwork for ${title} by ${artist}`} />*/}
                    <h2 className="title">{title}</h2>
                    {/*<h3 className="artist">{artist}</h3>*/}
                    <p>Audio ref {audioRef.current.currentTime}</p>
                </div>

                <AudioControls
                    isPlaying={isPlaying}
                    onPlayPauseClick={setIsPlaying}
                    // onPrevClick={() => {}}
                    // onNextClick={() => {}}
                />
                <input
                    type="range"
                    value={trackProgress}
                    step="0.01"
                    min="0"
                    max={duration ? duration : `${duration}`}
                    className="progress"
                    onChange={(e) => onScrub(e.target.value)}
                    onMouseUp={onScrubEnd}
                    onKeyUp={onScrubEnd}
                    onTouchEnd={onScrubEnd}
                    style={{
                        background: trackStyling,
                        height: "5px",
                        // -webkit-appearance: none;
                        width: "100%",
                        marginBottom: "10px",
                        borderRadius: "8px",
                        // background: "#3b7677",
                        transition: "background 0.2s ease",
                        cursor: "pointer",
                    }}
                />
            </AudioPlayerComponent>
        </>
    );
};

const AudioPlayerComponent = styled.div`
     {
        max-width: 350px;
        border-radius: 20px;
        padding: 24px;
        box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
        margin: auto;
        margin-bottom: 3em;
        color: var(--white);
    }
`;

const PlayPause = styled.div`
     {
        background: none;
        border: none;
        cursor: pointer;
    }
`;

const AudioControlsContainer = styled.div`
     {
        display: flex;
        justify-content: space-between;
        width: 75%;
        margin: 0 auto 15px;
        .play svg,
        .pause svg {
            height: 40px;
            width: 40px;
        }
        //path {
        //    fill: var(--black);
        //}
    }
`;
