import React, { useEffect, useState } from "react";
import PostDecoder from "./postDecoder";
import styled from "styled-components";

const animationTime = 3500;

const ContentView = ({ id }) => {
    const [postData, setPostData] = useState();
    const [status, setStatus] = useState("loading");

    useEffect(() => {
        setPostData({
            title: "The Truth about Twitch",
            sendDate: new Date() - 5000,
            creatorDetails: {
                profileImage: "cands_logo.webp",
                creatorName: "Colin and Samir",
            },
            attachments: [
                {
                    type: "audio/mp3",
                    url: "twitch.mp3",
                    id: "audio",
                },
            ],
            content:
                "Twitch is a terrible place to build your audience. All categories are sorted by most-viewed so " +
                "only already large creators can get any sort of organic growth. Instead, the best way to grow your Twitch is to " +
                " refer people from your other channels to your Twitch." +
                "\n\n" +
                "Got any thoughts on other ways to grow? Let us know below!" +
                "\n\n\n" +
                "This is just an example of the kind of content you might want to post and how it could be presented. " +
                "In reality the audio snippets would be probably be longer in length and there may be videos or " +
                "pictures too. " +
                "\n\n" +
                "We're working on a bunch of new features from integrated comments to engagement metrics and we would " +
                "work with you to make the platform as effective as possible for your needs." +
                "\n\n\n",
        });

        new Promise((resolve) =>
            setTimeout(() => {
                setStatus("loaded");
                resolve();
            }, animationTime)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (status === "error") {
        return <ErrorComponent />;
    }
    return (
        <>
            {status === "loading" ? (
                <AnimContainer>
                    <span style={{ display: "inline-block", height: "100%", verticalAlign: "middle" }} />
                    <img
                        alt="TSC logo"
                        src="cands.jpg"
                        style={{ maxWidth: "100%", margin: "0 auto", verticalAlign: "middle" }}
                    />
                </AnimContainer>
            ) : null}
            {postData ? <PostDecoder postData={postData} /> : null}
        </>
    );
};
export default ContentView;

const ErrorComponent = () => <StyledError>Sorry, something went wrong. Try again later</StyledError>;
const StyledError = styled.div`
    color: #000;
    margin-top: 30px;
    text-align: center;
`;

const AnimContainer = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3000;
    background: #f6e922; //linear-gradient(to right, #ffffff 50%, #f6e922 50%);
    > * {
        max-width: 400px;
        margin: auto;
    }

    animation: fadeout 1000ms;
    animation-fill-mode: forwards;
    animation-delay: ${animationTime - 1500}ms;

    @keyframes fadeout {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
`;
