import { createContext } from "react";
import Linkify from "react-linkify";
import styled from "styled-components";
import AttachmentsMapper from "./attachmentsMapper";
import { AudioPlayer } from "../AudioPlayer";

// TODO: Share this component with the dashboard
export const PostContext = createContext("str");

const PostDecoder = ({ postData }) => {
    const creatorName = postData?.creatorDetails?.creatorName || "";
    const feedbackLink = getInfluencerFeedbackLink(creatorName);

    return (
        <PostContext.Provider
            value={{
                postData: postData,
                creatorName: creatorName,
                feedbackLink: feedbackLink,
            }}
        >
            <img
                alt="header with presenters Colin & Samir"
                src="candsbanner.jpg"
                style={{
                    maxWidth: "100%",
                    boxShadow: "rgba(149, 157, 165, 0.9) 0px 4px 10px",
                }}
            />

            <Limiter>
                <Creator>
                    {/*{postData?.creatorDetails?.profileImage ? (*/}
                    {/*    <CreatorPicture src={postData?.creatorDetails.profileImage} />*/}
                    {/*) : null}*/}
                    <div>
                        {/*<Name>{creatorName}</Name>*/}
                        <DateStyle>
                            {calculateTimeAgo(
                                postData?.sendDate ||
                                    postData?.executionDateTime ||
                                    postData?.creationDate ||
                                    new Date()
                            )}
                        </DateStyle>
                    </div>
                </Creator>
                <h1 style={{ lineHeight: "1em" }}>{postData.title}</h1>

                {/*<AudioPlayer audioSrc={postData.attachments[0].url} title="Twitch" />*/}

                {postData.attachments.map((attachment) => {
                    return (
                        <Attachment key={attachment.id}>
                            <AttachmentsMapper
                                postData={{
                                    ...attachment,
                                    type: attachment.mimeType ?? attachment.type,
                                }}
                                urlExist={true}
                            />
                        </Attachment>
                    );
                })}
                <PostText>
                    <Linkify>{postData.content}</Linkify>
                </PostText>
                <FeedbackAndContactContainer>
                    {feedbackLink && (
                        <>
                            <PostLink href={feedbackLink}>contact us</PostLink>
                            <PostLink href={feedbackLink}>feedback</PostLink>
                        </>
                    )}
                </FeedbackAndContactContainer>
            </Limiter>
        </PostContext.Provider>
    );
};
export default PostDecoder;

const INFLUENCER_TO_FEEDBACK_LINK = [
    ["colin", "https://sub.queercosmos.com/contact-us"],
    ["chris", "https://chris-corsini.com/contact-us"],
    ["amy", "https://amylea.co/contact-us"],
    ["melissa", "https://sub.iammelissaruiz.com/contact-us"],
    ["ali", "https://sub.blissandbalance.org/contact"],
    ["tessa", "https://theonenesssolution.com/contact-us"],
    ["tricia", "https://triciacarrcharm.com/contact"],
];

const getInfluencerFeedbackLink = (influencer) => {
    const lowerInfluencer = influencer.toLowerCase();
    return (
        INFLUENCER_TO_FEEDBACK_LINK.find(([influencerName]) => lowerInfluencer.match(influencerName))?.[1] ??
        (() => {
            console.log("Feedback link for influencer '" + influencer + "' not found");
            return "";
        })()
    );
};

const calculateTimeAgo = (givenTime) => {
    let time = givenTime;
    if (typeof time !== Date) {
        if (Number(givenTime)) {
            time = new Date(+givenTime);
        } else {
            time = new Date(givenTime);
        }
    }

    const intervals = [
        { label: "year", seconds: 31536000 },
        { label: "month", seconds: 2592000 },
        { label: "day", seconds: 86400 },
        { label: "hour", seconds: 3600 },
        { label: "minute", seconds: 60 },
        { label: "second", seconds: 1 },
    ];
    const seconds = Math.floor((Date.now() - time) / 1000);
    const interval = intervals.find((i) => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
};

const Limiter = styled.div`
    max-width: 400px;
    @media (min-width: 400px) {
        margin: auto;
    }
    padding: 0 20px 20px 20px;
`;

const Creator = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 5px;
    //margin-top: 20px;

    > div {
        /* Auto Layout */
        display: inline-block;
        margin-left: 15px;
        vertical-align: middle;
        margin-top: 5px;
    }
`;

// const Name = styled.span`
//     display: block;
//     font-weight: 500;
//     font-size: 1.5em;
//     color: #1e1e1e;
// `;

const DateStyle = styled.span`
    display: block;
    position: relative;

    font-size: 0.7em;
    color: #a7a7a7;
`;

// const CreatorPicture = styled.img`
//     width: 55px;
//     height: 55px;
//     border-radius: 50%;
//     /* border: 2px solid #2196f3; */
//     object-fit: cover;
// `;

const Attachment = styled.div`
    margin: 15px 0px;
`;

const PostText = styled.div`
    font-size: 1em;
    line-height: 1.2em;
    letter-spacing: -0.006em;
    white-space: pre-line;

    color: #000000;
`;

const FeedbackAndContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`;

const PostLink = styled.a``;
