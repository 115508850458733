import "@mux-elements/mux-video";
import { createRef, useContext, useState } from "react";
import styled from "styled-components";

import { PostContext } from "./postDecoder";

import { muxEnvKey } from "../config";

// TODO: Share this component with the dashboard
const MuxVideoPlayer = ({ videoData }) => {
    let videoId = videoData.url.split("/")[videoData.url.split("/").length - 1];

    const { postData, creatorName } = useContext(PostContext);

    const [isPlayButtonVisible, setPlayButtonVisible] = useState(true);

    const videoRef = createRef();

    const togglePlay = () => {
        const video = videoRef.current;

        // TODO: mux-video tag should be refactored
        //  back to video element to prevent such stuff
        video.shadowRoot.children[1].onpause = (e) => {
            setPlayButtonVisible(true);
        };

        if (video.paused || video.ended) {
            video.play();
            setPlayButtonVisible(false);
        } else {
            video.pause();
            setPlayButtonVisible(true);
        }
    };

    return (
        <div>
            <Playbutton onClick={togglePlay} style={{ display: isPlayButtonVisible ? "" : "none" }} />
            <mux-video
                ref={videoRef}
                poster={`https://image.mux.com/${videoId}/thumbnail.jpg`}
                style={{
                    width: "100%",
                }}
                controls
                playsinline
                playback-id={videoId + "?fit_mode=smartcrop"}
                env-key={muxEnvKey}
                stream-type="on-demand"
                metadata-video-title={postData.title}
                metadata-video-id={videoData.id}
                metadata-video-producer={creatorName}
                metadata-video-series={postData.id}
            ></mux-video>
        </div>
    );
};

const Playbutton = styled.div`
    position: absolute;
    width: calc(100vw - 55px);
    height: 200px;
    max-width: 400px;
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default MuxVideoPlayer;
