import React from "react";
import "./App.css";
import ContentView from "./contentView";

function App() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const elementId = urlParams.get("el");

    return <ContentView id={elementId} />;
}

export default App;
