import MuxVideoPlayer from "./MuxVideoPlayer";

// TODO: Share this component with the dashboard
const AttachmentsMapper = ({ postData, urlExist }) => {
    if (!postData.type) {
        console.log("no type");
        return <></>;
    }

    if (postData.type.includes("image")) {
        return (
            <div key={postData}>
                <img
                    style={{ width: "100%", height: "100%" }}
                    src={urlExist ? postData.url : URL.createObjectURL(postData)}
                    alt="an attachment from the post" // TODO: Make this more descriptive
                ></img>
            </div>
        );
    }
    if (postData.type.includes("video")) {
        if (urlExist) {
            return <MuxVideoPlayer videoData={postData}></MuxVideoPlayer>;
        }
        // this is for the attachment preview before upload
        return (
            <div key={postData}>
                <video style={{ width: "100%", height: "100%" }} controls>
                    <source src={URL.createObjectURL(postData)} type={postData.type} />
                </video>
            </div>
        );
    }
    if (postData.type.includes("audio")) {
        return (
            <div key={postData}>
                <audio controls>
                    <source src={urlExist ? postData.url : URL.createObjectURL(postData)} type={postData.type} />
                </audio>
            </div>
        );
    }

    return <>{console.log("usuported type")}</>;
};

export default AttachmentsMapper;
